<template>
  <div>
    <app-page-detail page-title="Detail BA Checklist ABD" page-toolbar-title="Detail BA Checklist ABD" :record-not-found="notFound">
      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()" @callback-delete="goBack()"></action-button>
      </template>

      <v-row>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" md="12">
              <card-expansion title="General" class="mb-3" :loading="loading">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.document_no_" placeholder="No." label="No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.document_date" type="date" placeholder="Doc. Date" label="Doc. Date" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.area_code" placeholder="Area Code" label="Area Code" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.status" placeholder="Status" label="Status" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="mb-2"></v-divider>

                <v-row>
                  <v-col cols="6" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.customer_no_" placeholder="Customer No." label="Customer No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.customer_name" placeholder="Customer Name" label="Customer Name" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field dense disabled readonly v-model="baabd_check_header.campaign_no_" placeholder="Campaign No." label="Campaign No." outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense disabled readonly v-model="baabd_check_header.campaign_description" placeholder="Campaign Description" label="Campaign Description" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mb-2"></v-divider>
                <v-row>
                  <v-col cols="6" md="6">
                    <app-text-field dense disabled readonly v-model="baabd_check_header.checklist_date" placeholder="Check List Date" label="Check List Date" type="date" outlined hide-details class="mb-2" clearable></app-text-field>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="Detail" class="mb-6" :loading="loading" no-padding>
                <template slot="action">
                  <div style="max-width: 300px">
                    <v-text-field v-model="datatable_detail.search" label="Search" outlined dense hide-details small></v-text-field>
                  </div>
                </template>
                <v-row>
                  <v-col>
                    <v-data-table :items="baabd_check_detail" :headers="datatable_detail.headers" :loading="datatable_detail.loading" class="elevation-0" :search="datatable_detail.search" dense :sort-by="datatable_detail.sortBy" :sort-desc="datatable_detail.sortDesc" fixed-header height="360px">
                      <template v-slot:[`item.po_contract_no_`]="{ item }">
                        <span v-if="item.line_type == 'PO/KONTRAK'" class="font-weight-bold">{{ item.po_contract_no_ }}</span>
                        <span v-else class="text--disabled">{{ item.po_contract_no_ }}</span>
                      </template>
                      <template v-slot:[`item.copy_invoice`]="{ item }">
                        <span v-if="item.line_type == 'INVOICE'" class="font-weight-bold">{{ item.copy_invoice }}</span>
                        <span v-else class="text--disabled">{{ item.copy_invoice }}</span>
                      </template>
                      <template v-slot:[`item.invoice_receipt`]="{ item }">
                        <span v-if="item.line_type == 'TANDA TERIMA'" class="font-weight-bold">{{ item.invoice_receipt }}</span>
                        <span v-else class="text--disabled">{{ item.invoice_receipt }}</span>
                      </template>
                      <template v-slot:[`item.file_exist`]="{ item }">
                        <div class="mx-auto d-block text-center">
                          <v-checkbox readonly v-model="item.file_exist" value hide-details class="my-0 py-0" :label="item.file_exist ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="Rekonsiliasi Hutang Piutang" class="mb-6" :loading="loading">
                <v-row>
                  <v-col cols="12" md="3">
                    <label class="d-block my-1 py-1">Berita Acara</label>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox readonly v-model="baabd_check_header.berita_acara_check" value hide-details class="my-1 py-1" :label="baabd_check_header.berita_acara_check ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field readonly label="Keterangan Berita Acara" v-model="baabd_check_header.berita_acara_remark" hide-details outlined dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <label class="d-block my-1 py-1">MOM</label>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-checkbox readonly v-model="baabd_check_header.mom_check" value hide-details class="my-1 py-1" :label="baabd_check_header.mom_check ? 'Ada' : 'Tidak Ada'"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field readonly label="Keterangan MOM" v-model="baabd_check_header.mom_remark" hide-details outlined dense></v-text-field>
                  </v-col>
                </v-row>
              </card-expansion>

              <card-expansion title="List Approval" class="mb-3">
                <v-row>
                  <v-col cols="12">
                    <ApprovalList v-bind:data-list="baabd_check_approval"></ApprovalList>
                  </v-col>
                </v-row>
              </card-expansion>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :baabd_check_header="baabd_check_header"></document-info>
          <app-comment :document_id="parseInt(baabd_check_header.id)" :source_document="baabd_check_header.source_document" :document_no_="baabd_check_header.document_no_"></app-comment>
        </v-col>
      </v-row>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from "./components/ApprovalList.vue";
import DocumentInfo from "./components/DocumentInfo.vue";
import ActionButton from "./components/ActionButton.vue";
// import SetCloseDocument from "./components/SetCloseDocument.vue";

export default {
  components: {
    // CommentList,
    ApprovalList,
    DocumentInfo,
    ActionButton,
    // SetCloseDocument,
  },
  data() {
    return {
      id: null,
      loading: false,
      data_detail: {},
      baabd_check_header: {},
      baabd_check_detail: [],
      baabd_check_approval: [],
      loading_save: false,
      campaign_list: [],
      search_campaign: "",
      loading_search_campaign: false,
      selected_campaign: null,

      datatable_detail: {
        search: "",
        loading: false,
        sortBy: ["po_contract_no_", "copy_invoice", "invoice_receipt"],
        sortDesc: [false, false, false],
        headers: [
          // { text: "#", value: "action" },
          // { text: "Type", value: "line_type" },
          { sortable: false, text: "PO/Kontrak", value: "po_contract_no_", class: "col-md-3" },
          { sortable: false, text: "Copy Invoice", value: "copy_invoice", class: "col-md-2" },
          { sortable: false, text: "Tanda Terima", value: "invoice_receipt", class: "col-md-2" },
          { sortable: false, text: "Kelengkapan", value: "file_exist", class: "col-md-2" },
          { sortable: false, text: "Keterangan", value: "remark", class: "col-md-3" },
        ],
      },

      notFound: false,
    };
  },
  computed: {
    dtId() {
      return parseInt(this.$route.query.id);
    },
    vatValue() {
      var total = 0;
      this.baabd_check_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return !isNaN(total * (this.baabd_check_header.vat / 100)) ? total * (this.baabd_check_header.vat / 100) : 0;
    },
    totalAmount() {
      var total = 0;
      this.baabd_check_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      return total;
    },
    dppAmount() {
      var total = 0;
      this.baabd_check_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });

      if (this.baabd_check_header.vat == 0) {
        return 0;
      }

      const vat1 = 11 / 100;
      const vat2 = this.baabd_check_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return !isNaN(rs) ? rs : 0;
    },
    grandTotal() {
      var total = 0;
      this.baabd_check_detail.forEach((item) => {
        total += item.quantity * item.unit_price;
      });
      // tambahkan pajak
      // total += total * (this.baabd_check_header.vat / 100);

      const vat1 = 11 / 100;
      const vat2 = this.baabd_check_header.vat / 100;

      const rs = vat2 * ((vat1 / vat2) * total);
      return isNaN(total + rs) ? 0 : total + rs;
    },
  },
  methods: {
    refreshDetail() {
      this.getDetail(true);
    },

    /**
     * getDetail
     * Method untuk mengambil data detail dokumen
     */
    async getDetail(refresh = false) {
      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      if (refresh == true) this.AxiosStorageRemove("GET", "baabdcheck/detail");

      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.dtId,
        },
        cacheConfig: true,
      };
      await this.$axios
        .get("baabdcheck/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.baabd_check_header = res.data.data.baabd_check_header;
          this.baabd_check_detail = res.data.data.baabd_check_detail;
          this.baabd_check_approval = res.data.data.baabd_check_approval;

          this.selected_campaign = this.baabd_check_header.campaign_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          console.log("error.response", error.response);

          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },
  },

  beforeMount() {
    if (typeof this.$route.query.id !== "undefined") {
      this.id = parseInt(this.$route.query.id);
    } else {
      this.id = parseInt(this.$route.params.id);
    }
  },

  mounted() {
    this.getDetail(true);
  },
};
</script>
<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  vertical-align: top;
  padding-top: 5px;
}
</style>
