<template>
  <div>
    <v-sheet elevation="1">
      <v-data-table dense :items="dt_listdata" :headers="headers" :loading="loading">
        <template v-slot:top>
          <v-toolbar flat dense>
            <v-toolbar-title>Approval</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon text @click="getData(true)" v-if="dataId != 0 "><v-icon>mdi-sync</v-icon></v-btn>
          </v-toolbar>
          <v-divider></v-divider>
        </template>
        <template v-slot:[`item.approved`]="{ item }">
          <span v-if="item.approved == '1'">
            <v-icon color="primary">mdi-check</v-icon>
          </span>
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    dataList: [],
    dataId: {
      type: Number,
      default: 0
    },
  },
  computed:{
    dt_listdata(){
      if(typeof this.dataList === 'undefined') return this.listdata;
      
      return (this.dataList.length >= 0) ? this.dataList : this.listdata
    },
    dt_id(){
      return (this.dataId >= 0) ? this.dataId : this.id
    },
  },
  data: () => {
    return {
      loading: false,
      headers: [
        { text: "Approval", value: "approval_label", sortable: false },
        { text: "User", value: "username", sortable: false },
        { text: "Approval Status", value: "approved", sortable: false, align: 'center' },
        { text: "Approve Date", value: "approved_date", sortable: false },
      ],
      id: 0,
      listdata: []
    };
  },
  methods: {
    async getData(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "baabdcheck/approval-list");
      this.loading = true;
      await this.$axios
        .get("baabdcheck/approval-list", {
          params: {
            id: this.dt_id,
          },
        })
        .then((res) => {
          this.listdata = res.data.data.results;
          this.listdata.forEach(item => {
            this.id = item.header_id;
          })
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    if (typeof this.dataId !== "undefined" && this.dataId > 0) {
      this.id = this.dataId;
      this.getData();
    } else {
      this.listdata = this.dataList;
      this.listdata.forEach(item => {
        this.id = item.header_id;
      })
    }
  },
};
</script>
